import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable, tap } from 'rxjs';
import { AuthenticationService } from '../../pages/services/authentication.service';
import { IDataAlegias, IRespAlergias } from '../interfaces/alergias.interface';
import { IDataCirugias, IRespCirugias } from '../interfaces/cirugias.interface';
import {
  IDataEnfermedades,
  IRespEnfermedades,
} from '../interfaces/enfermedades.interface';
import {
  IDataEspecialidades,
  IRespEspecialidades,
} from '../interfaces/especialidades.interface';
import {
  IDataMedicamento,
  IRespMedicamentos,
} from '../interfaces/tratamiento/medicamentos.interface';

@Injectable({
  providedIn: 'root',
})
export class ServiceFormAnamnesisService {
  private endpointUrlBase = `${environment.baseURL}datos-medicos`;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService
  ) {}

  getDatosMedicos(): Observable<any> {
    const data = localStorage.getItem('datosMedicos') || '';
    return this.httpClient.get<any>(this.endpointUrlBase).pipe(
      tap((data) => {
        localStorage.setItem('datosMedicos', JSON.stringify(data));
      })
    );
  }

  getEspercialidades(): IDataEspecialidades[] {
    const data = localStorage.getItem('datosMedicos') || '';
    const jsonData = JSON.parse(data);
    const especialidades = jsonData.data.especialidades;
    return especialidades;
  }

  getAlergias(): IDataAlegias[] {
    const data = localStorage.getItem('datosMedicos') || '';
    const jsonData = JSON.parse(data);
    const alergias = jsonData.data.alergias;
    return alergias;
  }

  getEnfermedades(): IDataEnfermedades[] {
    const data = localStorage.getItem('datosMedicos') || '';
    const jsonData = JSON.parse(data);
    const enfermedades = jsonData.data.enfermedades;
    return enfermedades;
  }

  getCirugias(): IDataCirugias[] {
    const data = localStorage.getItem('datosMedicos') || '';
    const jsonData = JSON.parse(data);
    const cirugias = jsonData.data.cirugias;
    return cirugias;
  }

  getMedicamentos(): IDataMedicamento[] {
    const data = localStorage.getItem('datosMedicos') || '';
    const jsonData = JSON.parse(data);
    const medicamentos = jsonData.data.medicamentos;
    return medicamentos;
  }
}
